import React from 'react';
import { Modal as AntModal } from 'antd';
import styled from 'styled-components';

interface IModal {
    width?: number
    title: string
    visible: boolean
    onOk: any
    onCancel: any
    children?: any,
    cancelText?: string,
    okText?: string,
}

export const Modal: React.FC<IModal> = ({ width = 520, title, visible, onOk, onCancel, children, cancelText, okText }: IModal) => {
    return (
      <StyledModal
        width={width}
        title={title}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        cancelText={cancelText}
        okText={okText}
      >
        {children}
      </StyledModal>
    );
};

const StyledModal = styled(AntModal)`

  && .ant-modal-content {
    border-radius: 20px
  }

  && .ant-modal-title {
    color: #fff;
    font-weight: 600;
  }
  
  && .ant-modal-header {
    border-radius: 20px 20px 0 0;
    
    background-color: ${({ theme }) => theme.colors.greens[0]};
  };

  && .ant-modal-footer div {
    display: flex;
    justify-content: center;
  };

  && .ant-modal-footer div button {
    border-radius: 15px;
    width: 25%;
    height: 35px;
  };
`;