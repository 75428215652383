import { ReduxAction } from 'common/types/reduxAction';
import { LectorsActions } from '../actionTypes';

export const initialState = {
    showLectorsModal: false,
    editLectorsData: {}
};

const lectorsReducers = (state = initialState, { type, payload }: ReduxAction) => {
    switch (type) {
        case LectorsActions.TOGGLE_LECTOR_MODAL:
            return { ...state, showLectorsModal: payload };
        case LectorsActions.SET_EDIT_LECTOR_DATA:
            return { ...state, editLectorsData: payload };
        case LectorsActions.CREATE_LECTOR:
            return state;
        case LectorsActions.CREATE_LECTOR_ERROR:
            return state;
        case LectorsActions.EDIT_LECTOR:
            return state;
        case LectorsActions.EDIT_LECTOR_ERROR:
            return state;
        default:
            return state;
    }
};

export default lectorsReducers;
