import React from 'react';
import { StyledFormItem } from '../FormItem/styled';
import { StyledSwitch } from './styled';

type SwitchProps = {
    label: string;
    name: string;
    rules?: any;
    defaultValue?: boolean
};

export const Switch: React.FC<SwitchProps> = ({ label, name, rules = [], defaultValue = false}: SwitchProps) => {
    return (
      <StyledFormItem label={label} name={name} rules={rules} valuePropName='checked'>
        <StyledSwitch defaultChecked={defaultValue}/>
      </StyledFormItem>
    );
};
