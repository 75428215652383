import React from 'react';
import { StyledTopLayout, StyledContentWrapper } from 'core/components/MainLayout/styled';
import { Layout } from 'antd';

const LoginLayout: React.FC = ({ children }) => {
    return (
      <StyledTopLayout>
        <Layout>
          <StyledContentWrapper>
            {children}
          </StyledContentWrapper>
        </Layout>
      </StyledTopLayout>
      );
};

export default LoginLayout;