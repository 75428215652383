import React from 'react';
import { Input as AntInput } from 'antd';
import { StyledFormItem } from '../FormItem/styled';

type InputProps = {
    label: string;
    name: string;
    rules?: any;
    placeholder?: string;
    type?: string;
};

const getInputType = (type: string, name: string, placeholder: string) => {
    switch (type) {
        case 'password':
            return <AntInput.Password name={name} placeholder={placeholder} />;
        case 'textarea':
            return <AntInput.TextArea name={name} placeholder={placeholder} />;
        default:
            return <AntInput name={name} placeholder={placeholder} />;
    }
};

export const Input: React.FC<InputProps> = ({ label, name, rules = [], placeholder = '', type = '' }: InputProps) => {
    return (
      <StyledFormItem label={label} name={name} rules={rules}>
        {getInputType(type, name, placeholder)}
      </StyledFormItem>
    );
};
