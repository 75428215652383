import { ISeminar } from "common/types/seminar";
import moment from 'moment';
import { DateFormat, TimeFormat } from "common/constants/timeFormats";
import { IUserRegistration } from "common/types/userRegistration";

export function getSeminarFields(fields: any, avizURL: string, imageURL: string) {
    const data: ISeminar = {
        name: fields.name,
        title: fields.title,
        startDate: moment(
            fields.dates[0],
            DateFormat.FullDateCalendar
          ).format(DateFormat.FullDateCalendar),
        endDate:  moment(
            fields.dates[1],
            DateFormat.FullDateCalendar
          ).format(DateFormat.FullDateCalendar),
        time: moment(fields.time).format(TimeFormat.HoursAndMinutes),
        description: fields.description,
        price: fields.price,
        lectors: fields.lectors,
        program: fields.program,
        imageURL,
        avizURL
    };

    return data
};

export function populateSeminarFields(form: any, seminarData: ISeminar) {
  form.setFieldsValue({ 
    name: seminarData.name,
        title: seminarData.title,
        dates: [moment(seminarData.startDate, DateFormat.FullDateCalendar), moment(seminarData.endDate, DateFormat.FullDateCalendar)],
        time: moment(seminarData.time, TimeFormat.HoursAndMinutes),
        description: seminarData.description,
        price: seminarData.price,
        lectors: seminarData.lectors,
        program: seminarData.program
   });
};

export function getRegistrationSeminarFields(fields: any, seminarId: string) {
  const data: IUserRegistration = {
      name: fields.name,
      company: fields.company,
      phone: fields.phone,
      email: fields.email,
      seminarId
  };

  return data
};