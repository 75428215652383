import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .ant-btn.ant-btn-primary {
    background-color: ${({ theme }) => theme.colors.greens[0]};
    border-color: ${({ theme }) => theme.colors.greens[0]};

    :hover {
        color: #fff;
        background-color: ${({ theme }) => theme.colors.greens[1]};
        border-color: ${({ theme }) => theme.colors.greens[1]};
    }

    :focus {
        color: #fff;
        background-color: ${({ theme }) => theme.colors.greens[1]};
        border-color: ${({ theme }) => theme.colors.greens[1]};
    }
  };

  .ant-btn {
    :hover {
        color: ${({ theme }) => theme.colors.greens[1]};
        border-color: ${({ theme }) => theme.colors.greens[1]};
    }

    :focus {
        color: ${({ theme }) => theme.colors.greens[1]};
        border-color: ${({ theme }) => theme.colors.greens[1]};
    }
  };
`;

export default GlobalStyle;