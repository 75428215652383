import React from 'react';
import { Modal } from 'common/components/Modal';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form } from 'antd';
import { ICredentials } from 'common/types/credentials';
import { signIn, resetAuthError } from 'core/Redux/actions/authActions';
import { toggleLoginModal } from 'core/Redux/actions/mainActions';
import { TextButton } from 'common/components/TextButton';
import { Input } from 'common/components/Input';

const LoginModal: React.FC = ({
  showLoginModal,
  toggleLoginModal,
  signIn,
  authError,
  resetAuthError
}: any) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleOk = () => {
    form.validateFields().then(values => {
      signIn(values);
    });
  };

  const handleSignUpClick = () => {
    resetAuthError();
    toggleLoginModal(false);
  };

  return (
    <Modal
      title={t('fieldLabels.loginModalTitle')}
      visible={showLoginModal}
      onOk={handleOk}
      onCancel={() => {
        form.resetFields();
        resetAuthError();
        toggleLoginModal(false);
      }}
    >
      <Form
        hideRequiredMark
        layout="vertical"
        form={form}
        style={{ textAlign: 'center' }}
        name="basic"
      >
        <Input
          label={t('fieldLabels.email')}
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: t('error.noEmail')
            }
          ]}
        />
        <Input
          label={t('fieldLabels.password')}
          name="password"
          type="password"
          rules={[
            {
              required: true,
              message: t('error.noPassword')
            }
          ]}
        />
        {authError && (
          <span style={{ color: 'red' }}>{t('error.loginFailed')}</span>
        )}
        <Link to="/signup">
          <TextButton
            marginTop="10px"
            onKeyPress={(e: any) => e.keyCode === 13 && handleSignUpClick}
            onClick={handleSignUpClick}
          >
            {t('fieldLabels.signUp')}
          </TextButton>
        </Link>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ main, auth }: any) => {
  return {
    showLoginModal: main.showLoginModal,
    authError: auth.authError
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleLoginModal: (show: boolean) => dispatch(toggleLoginModal(show)),
    signIn: (cred: ICredentials) => dispatch(signIn(cred)),
    resetAuthError: () => dispatch(resetAuthError())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);
