import React from 'react';
import { Breadcrumb, Layout, Typography } from 'antd';
import AdminSideMenu from 'features/Admin/Menu';
import { Button } from 'common/components/Button';
import { useDispatch } from 'react-redux';
import { signOut } from 'core/Redux/actions/authActions';

const { Header, Footer, Sider, Content } = Layout;

const AdminLayout: React.FC = ({ children }) => {

  const dispatch = useDispatch();

  const logoutAdmin = () => {
    dispatch(signOut())
  }

  return (
    <Layout>
      <Header style={{ padding: '15px' }}>
        <Typography.Title style={{ color: 'white', float: 'left' }} level={3}>
          {' '}
          Admin Dashboard{' '}
        </Typography.Title>
          <Button
            style={{ float: 'right' }}
            type="primary"
            onClick={logoutAdmin}
          >{`Logout`}</Button>
      </Header>
      <Layout>
        <Sider>
          <AdminSideMenu />
        </Sider>
        <Layout>
          <Breadcrumb style={{ margin: '16px 0' }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
          </Breadcrumb>
          <Content style={{ padding: '0 50px' }}>
            <div
              style={{
                background: '#fff',
                padding: 24,
                minHeight: 580
              }}
            >
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            This Page is for managing the econtabilitate.md site.
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
