export class UserRegistration {
    userRegistration: any = {
        name: '',
        company: '',
        phone: '',
        email: '',
        seminarId: '',
        registrationTime: 0  
    }    

    constructor(userRegistration: any = {}) {
        this.userRegistration.name = userRegistration.name;
        this.userRegistration.company = userRegistration.company;
        this.userRegistration.phone = userRegistration.phone;
        this.userRegistration.email = userRegistration.email;
        this.userRegistration.seminarId = userRegistration.seminarId;
        this.userRegistration.registrationTime = Date.now();
    }
}

export interface IUserRegistration {
    id?: string;
    name: string;
    company: string;
    phone: string;
    email: string;
    seminarId: string;
    registrationTime?: number;
};