import { ReduxAction } from 'common/types/reduxAction';
import { SeminarActions } from '../actionTypes';

export const initialState = {
  showSeminarModal: false,
  showRegistrationSeminarModal: false,
  registrationSeminarModalData: {
    name: '',
    type: '',
    title: '',
    startDate: '',
    endDate: '',
    time: '',
    description: '',
    price: null,
    lectors: [],
    program: '',
    downloadSeminar: '',
    downloadRegistrationForm: '',
    id: ''
  },
  editSeminarData: {}
};

const seminarReducers = (
  state = initialState,
  { type, payload }: ReduxAction
) => {
  switch (type) {
    case SeminarActions.TOGGLE_SEMINAR_MODAL:
      return { ...state, showSeminarModal: payload };
    case SeminarActions.TOGGLE_REGISTRAION_SEMINAR_MODAL:
      return { ...state, showRegistrationSeminarModal: payload };
    case SeminarActions.SET_EDIT_SEMINAR_DATA:
      return { ...state, editSeminarData: payload };
    case SeminarActions.SET_REGISTRATION_SEMINAR_DATA:
      return { ...state, registrationSeminarModalData: payload };
    case SeminarActions.CREATE_SEMINAR:
      return state;
    case SeminarActions.CREATE_SEMINAR_ERROR:
      return state;
    case SeminarActions.CREATE_USER_REGISTRATION:
      return state;
    case SeminarActions.CREATE_USER_REGISTRATION_ERROR:
      return state;
    case SeminarActions.EDIT_SEMINAR:
      return state;
    case SeminarActions.EDIT_SEMINAR_ERROR:
      return state;
    default:
      return state;
  }
};

export default seminarReducers;
