import React from 'react';
import { createSvg } from '../SVG';

export const Logo = () => {
       return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="200px" height="70px" viewBox="0 0 300 152"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,152.000000) scale(0.100000,-0.100000)"
                fill="#28a745" stroke="none">
                <path d="M20 760 l0 -750 1485 0 1485 0 0 750 0 750 -1485 0 -1485 0 0 -750z
m2820 0 l0 -600 -735 0 -735 0 0 600 0 600 735 0 735 0 0 -600z m-2110 373 c0
-5 -17 -17 -37 -28 -76 -41 -103 -134 -103 -357 0 -214 29 -314 102 -353 48
-26 48 -38 1 -29 -104 17 -208 95 -256 191 -95 186 -34 434 130 530 65 38 163
66 163 46z m269 -28 l54 -25 -7 -46 c-3 -26 -6 -64 -6 -85 0 -58 -20 -49 -59
26 -38 75 -84 119 -141 136 -65 20 -48 33 32 25 43 -4 94 -17 127 -31z m45
-601 l6 -81 -50 -22 c-76 -33 -200 -53 -200 -33 0 5 17 14 39 20 59 18 124 81
155 152 16 34 31 58 36 53 4 -4 10 -45 14 -89z"/>
                <path d="M2046 1124 c-98 -30 -178 -102 -225 -203 -23 -50 -26 -68 -26 -171 0
-104 3 -121 27 -172 49 -106 149 -188 252 -207 53 -10 61 1 15 19 -67 28 -102
116 -113 285 -8 107 7 271 29 337 17 48 72 108 100 108 8 0 15 5 15 10 0 13
-15 12 -74 -6z"/>
                <path d="M2200 1132 c0 -5 17 -14 38 -20 49 -15 111 -78 147 -148 33 -65 39
-60 47 48 l6 67 -42 21 c-64 30 -196 52 -196 32z"/>
                <path d="M2391 539 c-29 -65 -85 -120 -144 -145 -26 -10 -47 -23 -47 -27 0
-19 178 20 216 47 20 14 21 22 17 83 -8 94 -14 101 -42 42z"/>
            </g>
        </svg>
    
};