import { notification } from 'antd';
import { INotification } from 'common/types/notification';
import { MainActions } from '../actionTypes';
import { IAlertBar } from 'common/types/alertBar';
import { collections } from 'common/constants/collections';
import { documentIds } from 'common/constants/documentIds';
import { FILE_TYPES } from 'common/constants/fileTypes';
import { STORAGE_DIRECTORIES } from 'common/constants/storageDirectories';

export const toggleLoginModal = (show: boolean) => ({
  type: MainActions.TOGGLE_LOGIN_MODAL,
  payload: show
});

export const toggleEditAlertBarModal = (show: boolean) => ({
  type: MainActions.TOGGLE_EDIT_ALERT_BAR_MODAL,
  payload: show
});

export const editAlertBar = (alertBarData: IAlertBar) => {
  return (dispatch: any, getState: any, { getFirestore }: any) => {
    const firestore = getFirestore();

    firestore
      .collection(collections.CONFIGS)
      .doc(documentIds.ALERT_BAR)
      .set(alertBarData)
      .then(() => {
        dispatch({
          type: MainActions.EDIT_ALERT_BAR_SUCCESS,
          payload: alertBarData
        });
      })

      .catch((err: Error) => {
        dispatch({
          type: MainActions.EDIT_ALERT_BAR_FAILED,
          payload: err
        });
      });
  };
};

export const pushNotification = ({
  message,
  description,
  duration = 0,
  type = 'open'
}: INotification) => {
  return (dispatch: any) => {
    // @ts-ignore
    notification[type]({
      message,
      description,
      duration
    });
    dispatch({ type: MainActions.PUSH_NOTIFICATION });
  };
};

export const uploadFile = (
  type: string,
  directory: string,
  collection: any,
  files: any,
  getFirebase: any,
  dispatch: any,
  ts: any = Date.now()
) =>
  new Promise((resolve, reject) => {

    if (!files.length) {
      resolve('');
    }

    const metadata = {
      contentType: type
    };

    const productImagesRef = getFirebase()
      .storage()
      .ref(`${directory}/${collection}/${ts}-${files[0].name}`)
      .put(files[0].originFileObj, metadata);
    productImagesRef.on(
      'state_changed',
      (snapshot: any) => {},
      (error: any) => {
        dispatch({
          type: MainActions.UPLOAD_FILE_ERROR,
          payload: error
        });
        reject(error);
      },
      () => {
        getFirebase()
          .storage()
          .ref(`${directory}/${collection}`)
          .child(`${ts}-${files[0].name}`)
          .getDownloadURL()
          .then((url: string) => {
            resolve(url);
          });
      }
    );
  });

export const downloadFile = (type: string, url: string) => {
  return (dispatch: any, getState: any, { getFirebase }: any) => {
    if (!url) return;
    getFirebase()
      .storage()
      .refFromURL(url)
      .getDownloadURL()
      .then(function(downloadURL: string) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function(event) {
          var blob = xhr.response;
          console.log(xhr.response);
          blob = new Blob([xhr.response], { type });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'Aviz.docx';
          link.click();
        };
        xhr.open('GET', downloadURL);
        xhr.send();
      })
      .then(() => {
        dispatch({
          type: MainActions.DOWNLOAD_FILE_SUCCESS
        });
      })
      .catch(function(error: Error) {
        dispatch({
          type: MainActions.DOWNLOAD_FILE_ERROR,
          payload: error
        });
      });
  };
};


export const addImageToGallery = (images: any = []) => {
  const ts = Date.now();
  return (dispatch: any, getState: any, { getFirestore, getFirebase }: any) => {
    const firestore = getFirestore();

    uploadFile(FILE_TYPES.JPEG, STORAGE_DIRECTORIES.GALLERY, collections.GALLERY, images, getFirebase, dispatch, ts)
    .then(fileUrl => {

      firestore
        .collection(collections.GALLERY)
        .add({ url: fileUrl, name: `${ts}-${images[0].name}` })
        .then(() => {
          dispatch({
            type: "Uploaded successfully",
            payload: fileUrl
          });
        });
    })
  };
};

export const deleteFile = (id: string, name: string, directory: string, collection: string) => {
  return (dispatch: any, getState: any, { getFirestore, getFirebase }: any) => {
    const firestore = getFirestore();

    firestore
      .collection(collection)
      .doc(id)
      .delete()
      .then(() => {
        dispatch({
          type: "Deleted from db",
          payload: id
        });
      })

    getFirebase()
    .storage()
    .ref(`${directory}/${collection}/${collection}/${name}`)
    .delete()
    .then(() => {
      dispatch({
        type: "Deleted from storage",
        payload: id
      });
    })
    .catch(() => {
      dispatch({
        type: "Failed to deleted from storage",
        payload: id
      });
    })

  };
};