import React from 'react';
// import { StyledTimePicker } from './styled';
import { StyledFormItem } from '../FormItem/styled';
import { Select } from 'antd';

const { Option } = Select;

type MultipleSelectProps = {
  label: string;
  name: string;
  placeholder?: string,
  rules?: any;
  options: any
};

export const MultipleSelect: React.FC<MultipleSelectProps> = ({
  label,
  name,
  rules = [],
  options = [],
  placeholder = ''
}: MultipleSelectProps) => {
  const handleChange = (values: any) => {
    console.log(values);
  };

  return (
    <StyledFormItem label={label} name={name} rules={rules}>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder={placeholder}
        onChange={handleChange}
      >
        {options.map((option: any) => {
          return <Option key={option.value} value={option.value}>{option.label}</Option>
        })}
      </Select>
    </StyledFormItem>
  );
};
