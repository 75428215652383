import styled from 'styled-components';

export const StyledFooter = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 200px;
    background: #333;
    color: #fff;
    padding: 20px 0;

    > div {
        margin-bottom: 15px;
    }


    & a {
        color: #fff;              
    }
    & a:hover {
        color:  ${props => props.theme.colors.greens[0]};
    }    
`