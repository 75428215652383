import styled from 'styled-components';
import { Form } from 'antd';

export const StyledFormItem = styled(Form.Item)`
    margin-bottom: 0;
    && {
        .ant-form-item-label {
            font-weight: 600;
            top: 10px;
            > label::after {
                content: none;
            }
        }
    }
`;
