export const menuConfig = [
    {
        label: "menu.home",
        linkTo: '/'
    },
    {
        label: 'menu.seminars',
        linkTo: '/seminars'
    },
    {
        label: 'menu.lectors',
        linkTo: '/lectors'
    },
    {
        label: 'menu.aboutUs',
        linkTo: '/about'
    }
]