import React from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import { StyledFormItem } from '../FormItem/styled';

type CheckboxProps = {
    label: string;
    name: string;
    rules?: any;
    defaultValue?: boolean
};

export const Checkbox: React.FC<CheckboxProps> = ({ label, name, rules = [], defaultValue = false}: CheckboxProps) => {
    return (
      <StyledFormItem label={label} name={name} rules={rules} >
        <AntCheckbox defaultChecked={defaultValue}/>
      </StyledFormItem>
    );
};
