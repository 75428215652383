import styled from 'styled-components';
import {
    space,
    SpaceProps,
    flexbox,
    FlexboxProps,
    color,
    ColorProps,
    borderRadius,
    BorderRadiusProps,
    position,
    PositionProps,
    layout,
    LayoutProps,
    typography,
    TypographyProps
} from 'styled-system';

export const TextButton = styled.button<
    SpaceProps & ColorProps & BorderRadiusProps & PositionProps & FlexboxProps & LayoutProps & TypographyProps
>`
    background: none;
    border: none;
    color: ${props => props.theme.colors.greens[0]};
    font-weight: 700;
    font-size: 14px;
    outline: none;
    overflow: hidden;
    display: inline;
    :hover {
        cursor: pointer;
        filter: brightness(125%);
    }
    ${space}
    ${color}
    ${borderRadius}
    ${position}
    ${flexbox}
    ${layout}
    ${typography}
`;
