import styled from 'styled-components';
import { Menu } from 'antd';

export const StyledMenuItem = styled(Menu.Item)`
    :hover {
        color: #fff;
        background: ${props => props.theme.colors.greens[0]};
    }
    
`;

export const StyledMenu = styled(Menu)`
    padding: 0;
    padding-inline-start: 0;    
`;