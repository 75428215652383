import React from 'react';
import { StyledFormItem } from '../FormItem/styled';
import { Input } from 'antd';

type TextAreaProps = {
  label: string;
  name: string;
  rules?: any;
  minRows?: number;
  maxRows?: number;
};

export const TextArea: React.FC<TextAreaProps> = ({
  label,
  name,
  rules = [],
  minRows = 2,
  maxRows = 10
}: TextAreaProps) => {
  return (
    <StyledFormItem label={label} name={name} rules={rules}>
      <Input.TextArea allowClear autoSize={{ minRows, maxRows }} />
    </StyledFormItem>
  );
};
