import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { StyledContentWrapper, StyledTopLayout } from './styled';
import AlertBar from 'common/components/AlertBar';
import { useLocation } from 'react-router-dom';

const MainLayout: React.FC = ({ children }) => {
  const location = useLocation();

    return (
      <StyledTopLayout>
        <Header />
        <AlertBar isAdmin={false} visible={true} closable={true} />
          <StyledContentWrapper style={{ padding: location.pathname === "/" ? "0" : "30px" }}>
            {children}
          </StyledContentWrapper>
        <Footer />
      </StyledTopLayout>
    );
};

export default MainLayout;
