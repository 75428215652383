import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import routes from 'routes';
import PrivateRoute from '../PrivateRoute';
import { Route } from 'react-router-dom';
import { Loading } from 'common/components/Loading';

export const MainContent: React.FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
      {routes.map(( {subRoutes, layout: Layout}, i) =>
          <Route key={i} exact={subRoutes.some(r => r.exact)} path={subRoutes.map(r => r.path)}>
            <Layout>
              {subRoutes.map((subRoute,i)=>
                <PrivateRoute key={i} {...subRoute} />
              )}
            </Layout>
          </Route>
        )}
      </Switch>
    </Suspense>
  );
};
