import React from 'react';
import { StyledDatePicker } from './styled';
import { StyledFormItem } from '../FormItem/styled';

type RangePickerProps = {
    label: string;
    name: string;
    rules?: any;
    placeholder?: string;
    type?: string;
};

export const RangePicker: React.FC<RangePickerProps> = ({ label, name, rules = [], placeholder = '', type = '' }: RangePickerProps) => {
    return (
      <StyledFormItem label={label} name={name} rules={rules}>
        <StyledDatePicker />
      </StyledFormItem>
    );
};
