export enum FieldTypes {
  SELECT = 'select',
  TEXT = 'text',
  DATEPICKER = 'datepicker',
  RANGEPICKER = 'rangepicker',
  TIMEPICKER = 'timepicker',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  LINK = 'link',
  TEXTAREA = 'textarea',
  SWITCH = 'switch',
  MULTIPLESELECT = 'multipleselect'
}
