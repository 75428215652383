import { useState, useEffect } from 'react';

export const isMobileDevice = () => window.innerWidth <= 575;

export const useWindowSize = () => {

  const [isMobile, setIsMobile] = useState(isMobileDevice());

    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });

            setIsMobile(isMobileDevice());
        }

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return { windowSize, isMobile };
}