import { ICredentials } from "common/types/credentials";
import { INewUser } from "common/types/newUser";
import { collections } from 'common/constants/collections';
import { SignInActions } from '../actionTypes';

export const signIn = (credentials: ICredentials) => {
    return (dispatch: any, getState: any, { getFirebase }: any) => {
        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(() => {
            dispatch({ type: SignInActions.LOGIN_SUCCESS });
        }).catch((err: Error) => {
            dispatch({ type: SignInActions.LOGIN_ERROR, payload: err })
        })
    }
};

export const signOut = () => {
    return (dispatch: any, getState: any, { getFirebase }: any) => {
        const firebase = getFirebase();
        firebase.auth().signOut()
        .then(() => {
            dispatch({ type: SignInActions.LOGOUT_SUCCESS })
        })
    }
};

export const signUp = (newUser: INewUser) => {
    return (dispatch: any, getState: any, { getFirebase, getFirestore }: any) => {
        const firebase = getFirebase();
        const firestore = getFirestore();

        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then((resp: any) => {
            return firestore.collection(collections.USERS).doc(resp.user.uid).set({
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                companyName: newUser.companyName,
                email: newUser.email
            })
        }).then(() => {
            dispatch({ type: SignInActions.SIGN_UP_SUCCESS })
        }).catch((err: Error) => {
            dispatch({ type: SignInActions.SIGN_UP_ERROR, payload: err })
        })
    }
};

export const resetAuthError = () => ({
    type: SignInActions.RESET_AUTH_ERROR
});