import React from 'react';
import './App.css';
import { Provider as ReduxProvider , useSelector  } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ReactReduxFirebaseProvider , isLoaded  } from 'react-redux-firebase';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router';
import { createFirestoreInstance } from 'redux-firestore';
import firebase from 'firebase';
import { I18nextProvider } from 'react-i18next';
import { firebaseConfig } from 'configs/fb';
import i18n from 'configs/i18n';
import { theme } from 'theme';
import store from 'core/Redux/store';
import { MainContent } from 'core/components/MainContent';
import CommonComponents from 'core/components/CommonComponents';
import GlobalStyle from './globalStyles';

export const rrfProps = {
    firebase,
    config: firebaseConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
    presence: 'presence', // where list of online users is stored in database
    sessions: 'sessions',

};

function AuthIsLoaded({ children }: any) {
    const auth = useSelector((state: any) => state.firebase.auth)
    if (!isLoaded(auth)) return <div>Loading Screen...</div>;
        return children
}

export const history = createBrowserHistory();

function App() {
    return (
      <div className="App">
        <ReduxProvider store={store}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <AuthIsLoaded>              
              <ThemeProvider theme={theme}>
                <I18nextProvider i18n={i18n}>
                  <Router history={history}>
                    <GlobalStyle />
                    <CommonComponents />
                    <MainContent />
                  </Router>
                </I18nextProvider>
              </ThemeProvider>
            </AuthIsLoaded>
          </ReactReduxFirebaseProvider>
        </ReduxProvider>
      </div>
    );
}

export default App;
