import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { menuConfig } from './menuConfig';

const AdminSideMenu: React.FC = () => {
  return (
    <Menu
      style={{ height: '100vh', textAlign: 'start' }}
      mode="inline"
    >
      {menuConfig.map((item: any) => {
        return item.items ? (
          <Menu.SubMenu key={item.label} title={<span>{item.label}</span>}>
            {item.items.map((subItem: any) => {
              return (
                <Menu.Item key={subItem.label}>
                  <Link to={subItem.link}>{subItem.label}</Link>
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        ) : (
          <Menu.Item key={item.label}>
            <Link to={item.link}>{item.label}</Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default AdminSideMenu;
