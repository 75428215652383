import { FieldTypes } from 'common/constants/fieldTypes';

const {
  TEXT
} = FieldTypes;

export const SeminarRegistrationConfig = [
  [
    {
      id: 'name',
      type: TEXT,
      label: 'fieldLabels.name',
      rules: [
        {
          required: true,
          message: 'This field is required'
        }
      ]
    },
    {
      id: 'company',
      type: TEXT,
      label: 'fieldLabels.company',
      rules: []
    }
  ],
  [
    {
      id: 'phone',
      type: TEXT,
      minRows: 2,
      maxRows: 2,
      label: 'fieldLabels.phone',
      rules: [
        {
          required: true,
          message: 'This field is required'
        }
      ]
    },
    {
        id: 'email',
        type: TEXT,
        minRows: 2,
        maxRows: 2,
        label: 'fieldLabels.email',
        rules: [
          {
            required: true,
            message: 'This field is required'
          }
        ]
      }
  ]
];
