import firebase from 'firebase/app';
import 'firebase/firestore';
import { collections } from 'common/constants/collections';



export const firebaseConfig = {
    apiKey: 'AIzaSyA5xxJskHbASbzMX_O7XuT9fvIEq7_EhTM',
    authDomain: 'auto-project-7a103.firebaseapp.com',
    databaseURL: 'https://auto-project-7a103.firebaseio.com',
    projectId: 'auto-project-7a103',
    storageBucket: 'auto-project-7a103.appspot.com',
    messagingSenderId: '423308885607',
    appId: '1:423308885607:web:53e494e3dad626535b5ec2',
    measurementId: 'G-2SR7QSVDWN',
    userProfile: collections.USERS, // where profiles are stored in database
    useFirestoreForProfile: true,
};

firebase.initializeApp(firebaseConfig);

export default firebase;
