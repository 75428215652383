import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from 'common/locales/en/default.en.json';
import ro from 'common/locales/ro/default.ro.json';
import ru from 'common/locales/ru/default.ru.json';

const options = {
    interpolation: {
        escapeValue: false // not needed for react!!
    },

    debug: true,

    // lng: 'en',

    resources: {
        en: {
            common: en
        },
        ro: {
            common: ro
        },
        ru: {
            common: ru
        }
    },

    fallbackLng: 'en',

    ns: ['common'],

    defaultNS: 'common',

    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    }
};

i18n.use(LanguageDetector).init(options as any);

export default i18n;
