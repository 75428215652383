import React from 'react';
import { Select as AntSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyledFormItem } from '../FormItem/styled';

const { Option } = AntSelect;

type SelectProps = {
    label: string,
    name: string,
    rules?: any,
    options: any,
    showSearch?: boolean,
    placeholder?: string,
    onChange?: any,
    onFocus?: any,
    onBlur?: any,
    onSearch?: any
}

const Select: React.FC<SelectProps> = ({ label, name, rules, options, showSearch, placeholder, onChange, onFocus, onBlur, onSearch }: SelectProps) => {
    const { t } = useTranslation();
    return (
      <StyledFormItem label={label} name={name} rules={rules}>
        <AntSelect
          showSearch={showSearch}
          placeholder={placeholder}
          optionFilterProp="children"
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onSearch={onSearch}
        >
          {options.map((option: any) => <Option key={option.label} value={option.value}>{t(`fieldLabels.${option.label.toLowerCase()}`)}</Option>)}
        </AntSelect>
      </StyledFormItem>
    );
};

export default Select;