import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AntMenu } from './styled';
import { menuConfig } from './menuConfig';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Menu: React.FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const defaultItem = menuConfig.find(item => item.linkTo === location.pathname);
    const [selected, setSelected] = useState(defaultItem ? [defaultItem.label] : [menuConfig[0].label]);
    useEffect(() => {
      setSelected(defaultItem ? [defaultItem.label] : [menuConfig[0].label])
    }, [location.pathname])

    const handleClick = (e: any) => {
        setSelected([e.key]);
    };

    return (
        <AntMenu onClick={handleClick} mode="horizontal" selectedKeys={selected}>
          {menuConfig.map(({ label, linkTo }) => (
            <AntMenu.Item key={label}>
              <Link to={linkTo}>{t(label)}</Link>
            </AntMenu.Item>
            ))}
        </AntMenu>
    );
};

export default Menu;
