import { lazy }  from 'react';
import { ROUTES } from 'common/constants/routeNames';
import AdminLayout from 'core/components/AdminLayout';
import LoginLayout from 'core/components/LoginLayout';
import MainLayout from 'core/components/MainLayout';

const HomePage = lazy(() => import('pages/HomePage'));
const SeminarsPage = lazy(() => import('pages/SeminarsPage'));
const SeminarsDetails = lazy(() => import('pages/SeminarsDetailsPage'));
const AboutUsPage = lazy(() => import('pages/AboutUsPage'));
const LoginPage = lazy(() => import('pages/LoginPage'));
const LectorsPage = lazy(() => import('pages/LectorsPage'));
const AdminHomePage = lazy(() => import('pages/AdminHomePage'));
const AdminSeminarsPage = lazy(() => import('pages/AdminSeminarsPage'));
const AdminLectorsPage = lazy(() => import('pages/AdminLectorsPage'));
const AdminAnnouncementsPage = lazy(() => import('pages/AdminAnnouncementsPage'));
const AdminRegistrationsPage = lazy(() => import('pages/AdminRegistrationsPage'));

export default [
    {
        layout: MainLayout,
        subRoutes: [
            {
                path: ROUTES.HOME,
                component: HomePage,
                guarded: false,
                exact: true
            },
            {
                path: ROUTES.SEMINARS,
                component: SeminarsPage,
                guarded: false,
                exact: false
            },
            {
                path: ROUTES.LECTORS,
                component: LectorsPage,
                guarded: false,
                exact: false
            },
            {
                path: ROUTES.ABOUT,
                component: AboutUsPage,
                guarded: false,
                exact: false
            },
            {
                path: ROUTES.SEMINARS_DETAILS,
                component: SeminarsDetails,
                guarded: false,
                exact: false
            },
        ]
    },
    {
        layout: AdminLayout,
        subRoutes: [
            {
                path: ROUTES.ADMIN,
                component: AdminHomePage,
                guarded: true,
                exact: true
            },
            {
                path: ROUTES.ADMIN_SEMINARS,
                component: AdminSeminarsPage,
                guarded: true,
                exact: true
            },
            {
                path: ROUTES.ADMIN_LECTORS,
                component: AdminLectorsPage,
                guarded: true,
                exact: true
            },
            {
                path: ROUTES.ADMIN_ANNOUNCEMENTS,
                component: AdminAnnouncementsPage,
                guarded: true,
                exact: true
            },
            {
                path: ROUTES.ADMIN_REGISTRATIONS,
                component: AdminRegistrationsPage,
                guarded: true,
                exact: true
            }
        ]
    },
    {
        layout: LoginLayout,
        subRoutes: [
            {
                path: ROUTES.ADMIN_LOGIN,
                component: LoginPage,
                guarded: false,
                exact: false
            }
        ]
    }    
];