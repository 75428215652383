import React from 'react';
// import { Icon } from 'antd';
import styled from 'styled-components';
import { color } from 'styled-system';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingComponent = styled.div`
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 120px;
  ${color}
`;

export const Loading = () => (
  <LoadingComponent color="primary">
    <LoadingOutlined />
  </LoadingComponent>
);
