import React from 'react';
import { Menu, Dropdown as AntDropdown, Button } from 'antd';
import { TextButton } from 'common/components/TextButton';
import { StyledMenuItem, StyledMenu } from './styled';

type DropdownProps = {
    menu: any,
    onSelect: any,
    title: string,
    type?: string,
}

const Dropdown: React.FC<DropdownProps> = ({ menu, onSelect, title, type = 'button' }: DropdownProps) => {

    let DropdownType;
    switch(type) {
        case 'button':
            DropdownType = Button;
            break
        case 'text':
            DropdownType = TextButton;
            break
        default:
            DropdownType = Button;
    }
    const renderMenu = (
      <StyledMenu onClick={onSelect}>
        {menu.map((item: any) => <StyledMenuItem key={item.value}>{item.label}</StyledMenuItem>)}
      </StyledMenu>
    );

    return (
      <AntDropdown overlay={renderMenu} placement="bottomLeft">
        <DropdownType>{title}</DropdownType>
      </AntDropdown>
    );
};

export default Dropdown;