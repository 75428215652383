import { ROUTES } from "common/constants/routeNames";

export const menuConfig = [
    {
        label: 'Seminars',
        link: ROUTES.ADMIN_SEMINARS
    },
    {
        label: 'Announcements',
        link: ROUTES.ADMIN_ANNOUNCEMENTS,
    },
    {
        label: 'Lectors',
        link: ROUTES.ADMIN_LECTORS,
    },
    {
        label: 'Registrations',
        link: ROUTES.ADMIN_REGISTRATIONS,
    }
]