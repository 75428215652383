import { ReduxAction } from 'common/types/reduxAction';
import { MainActions } from '../actionTypes';

const initialState = {
  showSideMenu: false,
  showLoginModal: false,
  showEditAlertBarModal: false
};

const mainReducers = (state = initialState, { type, payload }: ReduxAction) => {
  switch (type) {
    case MainActions.TOGGLE_LOGIN_MODAL:
      return { ...state, showLoginModal: payload };
    case MainActions.TOGGLE_EDIT_ALERT_BAR_MODAL:
      return { ...state, showEditAlertBarModal: payload };
    case MainActions.PUSH_NOTIFICATION:
      return state;
    default:
      return state;
  }
};
export default mainReducers;
