import { collections } from 'common/constants/collections';
import { SeminarActions } from '../actionTypes';
import { ISeminar } from 'common/types/seminar';
import { uploadFile } from './mainActions';
import { FILE_TYPES } from 'common/constants/fileTypes';
import { STORAGE_DIRECTORIES } from 'common/constants/storageDirectories';

export const toggleSeminarModal = (show: boolean) => ({
  type: SeminarActions.TOGGLE_SEMINAR_MODAL,
  payload: show
});

export const toggleRegistrationSeminarModal = (show: boolean) => ({
  type: SeminarActions.TOGGLE_REGISTRAION_SEMINAR_MODAL,
  payload: show
});

export const setEditSeminarData = (data: ISeminar) => ({
  type: SeminarActions.SET_EDIT_SEMINAR_DATA,
  payload: data
});

export const createSeminar = (
  seminar: ISeminar,
  images: any = [],
  files: any = []
) => {
  return (dispatch: any, getState: any, { getFirestore, getFirebase }: any) => {
    const firestore = getFirestore();
    let imageURL: any = '';
    let avizURL: any = '';
    uploadFile(
      FILE_TYPES.JPEG,
      STORAGE_DIRECTORIES.SEMINAR_IMAGES,
      collections.SEMINARS,
      images,
      getFirebase,
      dispatch
    )
      .then(fileUrl => {
        imageURL = fileUrl ? fileUrl : seminar.imageURL;

        uploadFile(
          FILE_TYPES.DOCX,
          STORAGE_DIRECTORIES.AVIZ,
          collections.SEMINARS,
          files,
          getFirebase,
          dispatch
        ).then(fileUrl => {
          avizURL = fileUrl ? fileUrl : seminar.avizURL;

          firestore
            .collection(collections.SEMINARS)
            .add({ ...seminar, imageURL, avizURL })
            .then(() => {
              dispatch({
                type: SeminarActions.CREATE_SEMINAR,
                payload: seminar
              });
            });
        });
      })

      .catch((err: Error) => {
        dispatch({
          type: SeminarActions.CREATE_SEMINAR_ERROR,
          payload: err
        });
      });
  };
};

export const deleteSeminar = (seminarId: any) => {
  return (dispatch: any, getState: any, { getFirestore }: any) => {
    const firestore = getFirestore();

    firestore
      .collection(collections.SEMINARS)
      .doc(seminarId)
      .delete()
      .then(() => {
        dispatch({
          type: SeminarActions.DELETE_SEMINAR,
          payload: seminarId
        });
      })

      .catch((err: Error) => {
        dispatch({
          type: SeminarActions.DELETE_SEMINAR_ERROR,
          payload: err
        });
      });
  };
};

export const editSeminar = (
  seminarId: string,
  seminar: ISeminar,
  images: any = [],
  files: any = []
) => {
  return (dispatch: any, getState: any, { getFirestore, getFirebase }: any) => {
    const firestore = getFirestore();
    let imageURL: any = '';
    let avizURL: any = '';
    
    uploadFile(
      FILE_TYPES.JPEG,
      STORAGE_DIRECTORIES.SEMINAR_IMAGES,
      collections.SEMINARS,
      images,
      getFirebase,
      dispatch
    )
      .then(fileUrl => {
        imageURL = fileUrl ? fileUrl : seminar.imageURL;

        uploadFile(
          FILE_TYPES.DOCX,
          STORAGE_DIRECTORIES.AVIZ,
          collections.SEMINARS,
          files,
          getFirebase,
          dispatch
        ).then(fileUrl => {
          avizURL = fileUrl ? fileUrl : seminar.avizURL;
          console.log(avizURL);
          firestore
            .collection(collections.SEMINARS)
            .doc(seminarId)
            .set({ ...seminar, imageURL, avizURL })
            .then(() => {
              dispatch({
                type: SeminarActions.EDIT_SEMINAR,
                payload: seminar
              });
            });
        });
      })

      .catch((err: Error) => {
        dispatch({
          type: SeminarActions.EDIT_SEMINAR_ERROR,
          payload: err
        });
      });
  };
};

export const createRegistrationSeminar = (userRegistration: any) => {
  return (dispatch: any, getState: any, { getFirestore }: any) => {
    const firestore = getFirestore();

    firestore
      .collection(collections.USER_REGISTRATIONS)
      .add({ ...userRegistration })
      .then(() => {
        dispatch({
          type: SeminarActions.CREATE_USER_REGISTRATION,
          payload: userRegistration
        });
      })

      .catch((err: Error) => {
        dispatch({
          type: SeminarActions.CREATE_USER_REGISTRATION_ERROR,
          payload: err
        });
      });
  };
};

export const setRegistrationSeminarData = (data: ISeminar) => ({
  type: SeminarActions.SET_REGISTRATION_SEMINAR_DATA,
  payload: data
});

// export const downloadAviz = (seminarId: any) => {
//   return (dispatch: any, getState: any, { getFirestore, getFirebase }: any) => {
//     const firestore = getFirestore();

//     getFirebase()
//         .storage()
//         .ref('')
//         .

//     firestore
//       .collection(collections.SEMINARS)
//       .doc(seminarId)
//       .delete()
//       .then(() => {
//         dispatch({
//           type: SeminarActions.DELETE_SEMINAR,
//           payload: seminarId
//         });
//       })

//       .catch((err: Error) => {
//         dispatch({
//           type: SeminarActions.DELETE_SEMINAR_ERROR,
//           payload: err
//         });
//       });
//   };
// };
