import * as React from 'react';
import { Modal } from 'common/components/Modal';
import { FormGenerator } from 'common/components/FormGenerator';
import { SeminarRegistrationConfig } from './seminarRegistrationConfig';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getRegistrationSeminarFields } from 'core/services/seminars';
import { UserRegistration } from 'common/types/userRegistration';
import {
  createRegistrationSeminar,
  toggleRegistrationSeminarModal
} from 'core/Redux/actions/seminarsActions';
import { useTranslation } from 'react-i18next';


interface ISeminarRegistrationModalProps {
}

const SeminarRegistrationModal: React.FunctionComponent<ISeminarRegistrationModalProps> = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const showRegistrationSeminarModal = useSelector(
    (state: any) => state.seminars.showRegistrationSeminarModal
  );
  const seminar = useSelector(
    (state: any) => state.seminars.registrationSeminarModalData
  );
  const { id, title, startDate } = seminar;

  const handleModalOk = () => {
    form.validateFields().then((values: any) => {
      const data = getRegistrationSeminarFields(values, id || '');
      let { userRegistration } = new UserRegistration(data);
      dispatch(createRegistrationSeminar(userRegistration));
      dispatch(toggleRegistrationSeminarModal(false));
      form.resetFields();
    });
  };

  const handleModalCancel = () => {
    dispatch(toggleRegistrationSeminarModal(false));
    form.resetFields();
  };
  return (
    <Modal
      width={800}
      visible={showRegistrationSeminarModal}
      title={t('fieldLabels.registration')}
      onOk={handleModalOk}
      onCancel={handleModalCancel}
      okText={t('fieldLabels.register')}
      cancelText={t('fieldLabels.cancel')}
    >
      <div>{t('seminar.modal.title', { title, startDate })}</div>
      <FormGenerator form={form} config={SeminarRegistrationConfig} />
    </Modal>
  );
};

export default SeminarRegistrationModal;
