import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { getFirebase } from 'react-redux-firebase';
import firebase, { firebaseConfig } from 'configs/fb';
import logger from 'redux-logger';
import { rootReducers } from './reducers/rootReducers';

const store = createStore(
    rootReducers,
    compose(applyMiddleware(
        thunk.withExtraArgument({
            getFirebase,
            getFirestore
        }),
        logger
    ), reduxFirestore(firebase, firebaseConfig as any))
);

export default store;
