import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import LoginModal from 'features/LoginModal';
import Dropdown from 'common/components/Dropdown';
import { languages } from 'common/constants/languages';
import Menu from './Menu/index';
import { AntHeader, StyledFontAwesomeIcon, LogoWrapper, StyledLogo, StyledLanguageSelector } from './styled';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { Logo } from 'common/components/Logo';

const Header: React.FC = () => {
    const { i18n } = useTranslation();
    const language = languages.find((lang: any) => lang.value === i18n.language) || languages[0];
   
    return (
      <>
        <AntHeader>
          <LogoWrapper>
            <StyledLogo />
          </LogoWrapper>
          <Menu />
          <StyledLanguageSelector>    
              <Dropdown
                type="text"                
                onSelect={({ key }: any) => i18n.changeLanguage(key)}
                menu={languages}
                title={language.label}                
              />
          </StyledLanguageSelector>
        </AntHeader>
        <LoginModal />
      </>
    );
};

export default Header;
