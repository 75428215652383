import styled from 'styled-components';
import { Layout } from 'antd';

export const StyledContentWrapper = styled(Layout)`    
    position: relative;
    box-sizing: border-box;
    margin: 0;    
`;

export const StyledTopLayout = styled(Layout)`
    height: 100vh;
    && {
        .ant-layout {
            min-height: unset;
        }
    }
`;