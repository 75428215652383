import { ReduxAction } from 'common/types/reduxAction';
import { SignInActions } from '../actionTypes';

const initState = {
    authError: null
};

const authReducer = (state = initState, { type, payload }: ReduxAction) => {
    switch (type) {
        case SignInActions.LOGIN_ERROR:
            return {
                ...state,
                authError: payload
            };
        case SignInActions.LOGIN_SUCCESS:
            return {
                ...state,
                authError: null
            };
        case SignInActions.LOGOUT_SUCCESS:
            return state;
        case SignInActions.SIGN_UP_SUCCESS:
            return { ...state, authError: null };
        case SignInActions.RESET_AUTH_ERROR:
            return { ...state, authError: null };
        case SignInActions.SIGN_UP_ERROR:
            return {
                ...state,
                authError: payload
            };
        default:
            return state;
    }
};

export default authReducer;
