export class AlertBar {
  alertBar: any = {
    text: '',
    type: '',
    published: false,
    link: ''
  };

  constructor(alertBar: any = {}) {
    this.alertBar.text = alertBar.text;
    this.alertBar.type = alertBar.type;
    this.alertBar.published = alertBar.published;
    this.alertBar.link = alertBar.link;
  }
}

export interface IAlertBar {
  text: string;
  type: string;
  published: boolean;
  link?: string;
}
