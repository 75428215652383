import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { INotification } from 'common/types/notification';
import { pushNotification } from 'core/Redux/actions/mainActions';
import { ROUTES } from 'common/constants/routeNames';

const PrivateRoute = ({
  component: Component,
  auth,
  pushNotification,
  guarded,
  ...rest
}: any) => {
  const { t } = useTranslation();
  const notification: INotification = {
    message: t('fieldLabels.signIn'),
    description: t('notifications.pleaseSignIn'),
    duration: 5,
    type: 'info'
  };

  useEffect(() => {
    if (!auth.uid && guarded) {
      pushNotification(notification);
    }
  }, []);

  return (
    <Route
      {...rest}
      render={props =>
        !!auth.uid || !guarded ? (
            <Component {...props} />
        ) : (
          <Redirect to={ROUTES.ADMIN_LOGIN} />
        )
      }
    />
  );
};

const mapStateToProps = ({ firebase }: any) => ({
  auth: firebase.auth
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    pushNotification: (notification: INotification) =>
      dispatch(pushNotification(notification))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
