import React from 'react';
import SeminarRegistrationModal from 'features/Seminars/SeminarRegistrationModal';

const CommonComponents: React.FC = () => {
    return (
        <>
            <SeminarRegistrationModal />
        </>
    );
};

export default CommonComponents;