import styled from 'styled-components';
import { Button, Layout } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Logo as AppLogo } from 'common/components/Logo';

export const Logo = styled.div`
    color: ${props => props.theme.colors.reds[0]};
    float: right;
`;

export const AuthButton = styled(Button)`
    float: right;
    top: 15px;
    right: 25px;
`;

export const AntHeader = styled(Layout.Header)`
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.blacks[0]};
    color: #fff;
    opacity: 0.8;
    width: 100%;
    height: 250px;
    top: 0px;
    padding: 0 20px;
    justify-content: center;

    @media (${({ theme }) => theme.breakpoints.tablet}) {
        height: 140px;
    }
`

export const LogoWrapper = styled.h1`
    align-items: center;    
    justify-content: space-between;
    text-align: center;
    margin: 20px 0 0 0;
    
    @media (${({ theme }) => theme.breakpoints.tablet}) {
        margin-right: auto;
    }
`;

export const StyledLogo = styled(AppLogo)`
    width: 200px;
    background-color: ${({ theme }) => theme.colors.greens[0]};
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.greens[0]};
    margin-right: 8px;
`;

export const StyledLanguageSelector = styled.div`
    position: relative;
    top: -5px;

    @media (${({ theme }) => theme.breakpoints.tablet}) {
        width: 100px;
        margin-left: auto;

        position: unset;
        top: 0;
    }
`;
