import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import mainReducers from './mainReducers';
import authReducer from './authReducer';
import seminarReducers from './seminarReducers';
import lectorsReducers from './lectorsReducers';

export const rootReducers = combineReducers({
    auth: authReducer,
    main: mainReducers,
    seminars: seminarReducers,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    lectors: lectorsReducers
});
