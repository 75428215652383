import React from 'react';
import { StyledTimePicker } from './styled';
import { StyledFormItem } from '../FormItem/styled';

type TimePickerProps = {
    label: string;
    name: string;
    rules?: any;
    placeholder?: string;
    type?: string;
    format?: any;
};

export const TimePicker: React.FC<TimePickerProps> = ({ label, name, rules = [], placeholder = '', type = '', format='' }: TimePickerProps) => {
    return (
      <StyledFormItem label={label} name={name} rules={rules}>
        <StyledTimePicker format={format} />
      </StyledFormItem>
    );
};
