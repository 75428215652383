import styled from 'styled-components';
import { Menu } from 'antd';

export const AntMenu = styled(Menu)`
    background-color: ${({ theme }) => theme.colors.blacks[0]};

    li {
        margin: 0 !important;
    };

    && .ant-menu-item, .ant-menu-submenu-title {
        padding: 0 10px;
    };

    @media (${({ theme }) => theme.breakpoints.tablet}) {
        && .ant-menu-item, .ant-menu-submenu-title {
            padding: 0 20px;
        };
    }

    && .ant-menu-item-selected {
        border-bottom: 2px solid ${props => props.theme.colors.greens[0]};
    }

    && .ant-menu-item-selected a {
        color: ${props => props.theme.colors.greens[0]};
    }
    
    && a {
        color: white;
    }

    && a:hover {
        color: ${props => props.theme.colors.greens[0]};
    }

    && .ant-menu-item-active {
        border-bottom: 2px solid ${props => props.theme.colors.greens[0]};
    }    
`;
