import React, { Fragment } from 'react';
import { FieldTypes } from 'common/constants/fieldTypes';
import { Input } from '../Input';
import { Form, Row, Col } from 'antd';
import { RangePicker } from 'common/components/RangePicker';
import { TimePicker } from 'common/components/TimePicker';
import { TextArea } from 'common/components/TextArea';
import { MultipleSelect } from '../MultipleSelect';
import { TimeFormat } from 'common/constants/timeFormats';
import Select from '../Select';
import { Checkbox } from '../Checkbox';
import { Switch } from '../Switch';
import { useWindowSize } from 'core/hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

type FormGeneratorProps = {
  config: any;
  form: any;
};

export const FormGenerator: React.FC<FormGeneratorProps> = ({
  config,
  form
}: FormGeneratorProps) => {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const getFieldByType = (field: any) => {
    switch (field.type) {
      case FieldTypes.TEXT:
        return (
          <Input label={t(field.label)} name={field.id} rules={field.rules} />
        );
      case FieldTypes.SELECT:
        return (
          <Select
            options={field.options}
            label={t(field.label)}
            name={field.id}
            rules={field.rules}
          />
        );
      case FieldTypes.CHECKBOX:
        return (
          <Checkbox
            defaultValue={field.defaultValue || false}
            label={t(field.label)}
            name={field.id}
            rules={field.rules}
          />
        );
      case FieldTypes.SWITCH:
        return (
          <Switch
            defaultValue={field.defaultValue || false}
            label={t(field.label)}
            name={field.id}
            rules={field.rules}
          />
        );
      case FieldTypes.RANGEPICKER:
        return (
          <RangePicker
            label={t(field.label)}
            name={field.id}
            rules={field.rules}
          />
        );
      case FieldTypes.TIMEPICKER:
        return (
          <TimePicker
            format={field.format || TimeFormat.HoursAndMinutes}
            label={t(field.label)}
            name={field.id}
            rules={field.rules}
          />
        );
      case FieldTypes.TEXTAREA:
        return (
          <TextArea
            minRows={field.minRows || 2}
            maxRows={field.maxRows || 10}
            label={t(field.label)}
            name={field.id}
            rules={field.rules}
          />
        );
      case FieldTypes.MULTIPLESELECT:
        return (
          <MultipleSelect
            placeholder={field.placeholder || ''}
            options={field.options}
            label={t(field.label)}
            name={field.id}
            rules={field.rules}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Form
      hideRequiredMark
      form={form}
      style={{ textAlign: 'center' }}
      layout="vertical"
      name="basic"
      initialValues={{ remember: true }}
    >
      {config.map((fieldsRow: Array<any>, index: number) => (
        <Row gutter={[70, 0]} key={index}>
          {fieldsRow.map((field: any, index: number) => (
            <Fragment key={field.id || index}>
              <Col span={isMobile ? 24 : 24 / fieldsRow.length} key={field.id || index}>
                {getFieldByType(field)}
              </Col>
            </Fragment>
          ))}
        </Row>
      ))}
    </Form>
  );
};

export const getFieldById = (formConfig: any, fieldId: string) => {
  const rowWithField = formConfig.find((row: any) =>
    row.find((field: any) => field.id === fieldId)
  );

  return rowWithField.find((field: any) => field.id === fieldId);
};
