import React, { useState } from 'react';
import { Alert } from 'antd';
import { useFirestoreConnect } from 'react-redux-firebase';
import { collections } from 'common/constants/collections';
import { documentIds } from 'common/constants/documentIds';
import { useSelector } from 'react-redux';
import { AlertBar as AlertBarObj } from 'common/types/alertBar';
import { StyledAlert } from './styled';

type AlertBarProps = {
    visible: boolean;
    closable: boolean;
    isAdmin?: boolean;
}

const AlertBar: React.FC<AlertBarProps> = ({ visible, closable, isAdmin = false }: AlertBarProps) => {
  const [isVisible, setVisible] = useState(visible);
  const AlertComponent = isAdmin ? Alert : StyledAlert;

  useFirestoreConnect([
    {
      collection: collections.CONFIGS,
      doc: documentIds.ALERT_BAR
    }
  ]);

  const alertBarData = useSelector((state: any) => {
    return state.firestore.data[collections.CONFIGS]
      ? state.firestore.data[collections.CONFIGS][documentIds.ALERT_BAR]
      : new AlertBarObj();
  });

  const handleClose = () => {
    setVisible(false);
  };
  return alertBarData.published ? (
    <div style={{position: isAdmin ? "-moz-initial" : "relative"}}>
      {isVisible ? (
        <AlertComponent message={alertBarData.text} type={alertBarData.type} closable={closable} afterClose={handleClose} />
      ) : null}
    </div>
  ) : null;
};

export default AlertBar;