import { Theme } from 'styled-system';
import { transparentize } from 'polished';

const colors = {
    reds: ['red'],
    blacks: ['#0e1111'],
    blues: ['lightblue', '#1c9eff'],
    greens: ['#28a745', "#1e8235"],
    whites: ['#FFFAF1'],
    grays: ['#808080', '#ededed'],
}

const breakpoints = {
    mobile: 'max-width: 575px',
    tablet: 'min-width: 576px',
    tabletLarge: 'min-width: 768px',
    desktop: 'min-width: 992px',
    desktopLarge: 'min-width: 1200px',
    wide: 'min-width: 1440px',
    extraWide: 'min-width: 1920px',
};

const shadows: any = {
    small: `0 2px 4px ${transparentize(0.75, colors.grays[0])}`,
    medium: `0 12px 18px ${transparentize(0.5, colors.grays[0])}`,
    large: `0 15px 30px ${transparentize(0.25, colors.grays[0])}`,
    center: `0 0 20px ${transparentize(0.7, colors.grays[0])}`,
    focus: `0px 0px 0 3px ${transparentize(0.5, colors.grays[0])}`,
  };

const fontSizes = {
    xxxxl: '48px',
    xxxl: '32px',
    xxl: '24px',
    xl: '20px',
    lg: '18px',
    md: '16px',
    sm: '14px',
    xs: '12px',
};

export const theme: Theme = {
    colors,
    fontSizes,
    breakpoints,
    shadows    
};