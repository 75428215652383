export const ROUTES = {
    HOME: "/",
    ABOUT: '/about',
    SEMINARS: '/seminars',
    SEMINARS_DETAILS: '/seminar/:id',
    ADMIN: '/admin',
    LECTORS: '/lectors',
    ADMIN_LOGIN: '/admin/login',
    ADMIN_ANNOUNCEMENTS: '/admin/announcements',
    ADMIN_LECTORS: '/admin/lectors',
    ADMIN_REGISTRATIONS: '/admin/registrations',
    ADMIN_SEMINARS: '/admin/seminars'
}