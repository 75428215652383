import React from 'react';
import { StyledFooter } from './styled';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <div>
                <a href="https://www.facebook.com/econtabilitate.md"><FontAwesomeIcon icon={faFacebook} size={'3x'} /></a>            
            </div>
            <p>Copyright &copy; 2020 - PASILIUB-CONT S.R.L.</p>
        </StyledFooter>
    );
};

export default Footer;