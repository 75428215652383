export const MainActions = {
    TOGGLE_SIDEMENU: 'TOGGLE_SIDEMENU',
    TOGGLE_LOGIN_MODAL: 'TOGGLE_LOGIN_MODAL',
    PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
    REDIRECT: 'REDIRECT',
    TOGGLE_EDIT_ALERT_BAR_MODAL: 'TOGGLE_EDIT_ALERT_BAR_MODAL',
    EDIT_ALERT_BAR_SUCCESS: 'EDIT_ALERT_SUCCESS',
    EDIT_ALERT_BAR_FAILED: 'EDIT_ALERT_BAR_FAILED',
    UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
    UPLOAD_FILE_ERROR: 'UPLOAD_FILE_ERROR',
    DOWNLOAD_FILE_SUCCESS: 'DOWNLOAD_FILE_SUCCESS',
    DOWNLOAD_FILE_ERROR: 'DOWNLOAD_FILE_ERROR',
};

export const ProductActions = {
    CREATE_PRODUCT: 'CREATE_PRODUCT',
    CREATE_PRODUCT_ERROR: 'CREATE_PRODUCT_ERROR',
    DISPLAY_CREATE_PRODUCT_MODAL: 'DISPLAY_CREATE_PRODUCT_MODAL'
};

export const SeminarActions = {
    TOGGLE_SEMINAR_MODAL: 'TOGGLE_SEMINAR_MODAL',
    TOGGLE_REGISTRAION_SEMINAR_MODAL: 'TOGGLE_REGISTRAION_SEMINAR_MODAL',
    SET_EDIT_SEMINAR_DATA: 'SET_EDIT_SEMINAR_DATA',
    SET_REGISTRATION_SEMINAR_DATA: 'SET_REGISTRATION_SEMINAR_DATA',
    CREATE_SEMINAR: 'CREATE_SEMINAR',
    CREATE_USER_REGISTRATION: 'CREATE_USER_REGISTRATION',
    CREATE_USER_REGISTRATION_ERROR: 'CREATE_USER_REGISTRATION',
    CREATE_SEMINAR_ERROR: 'CREATE_SEMINAR_ERROR',
    EDIT_SEMINAR: 'EDIT_SEMINAR',
    EDIT_SEMINAR_ERROR: 'EDIT_SEMINAR_ERROR',
    DELETE_SEMINAR: 'DELETE_SEMINAR',
    DELETE_SEMINAR_ERROR: 'DELETE_SEMINAR_ERROR'
};

export const LectorsActions = {
    TOGGLE_LECTOR_MODAL: 'TOGGLE_LECTOR_MODAL',
    SET_EDIT_LECTOR_DATA: 'SET_EDIT_LECTOR_DATA',
    CREATE_LECTOR: 'CREATE_LECTOR',
    CREATE_LECTOR_ERROR: 'CREATE_LECTOR_ERROR',
    EDIT_LECTOR: 'EDIT_LECTOR',
    EDIT_LECTOR_ERROR: 'EDIT_LECTOR_ERROR',
    DELETE_LECTOR: 'DELETE_LECTOR',
    DELETE_LECTOR_ERROR: 'DELETE_LECTOR_ERROR'
};

export const SignInActions = {    
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_ERROR: 'SIGN_UP_ERROR',
    RESET_AUTH_ERROR: 'RESET_AUTH_ERROR'
};