import styled from 'styled-components';
import { Button as AntButton} from 'antd';
import {
    space,
    SpaceProps,
    flexbox,
    FlexboxProps,
    color,
    ColorProps,
    borderRadius,
    border,
    BorderProps,
    padding,
    PaddingProps,
    BorderRadiusProps,
    position,
    PositionProps,
    layout,
    LayoutProps,
    typography,
    TypographyProps
} from 'styled-system';

export const Button = styled(AntButton)<
    SpaceProps & ColorProps & BorderRadiusProps & PositionProps & FlexboxProps & LayoutProps & TypographyProps & BorderProps & PaddingProps
>`
    ${space}
    ${padding}
    ${border}
    ${color}
    ${borderRadius}
    ${position}
    ${flexbox}
    ${layout}
    ${typography}
`;
